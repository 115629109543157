import React, { useEffect } from 'react'
import Rejected from './Components'
import './RejectScreen.module.css'

const RejectScreen = props => {
  useEffect(() => {}, [props])

  return (
    <>
      <Rejected />
    </>
  )
}

export default RejectScreen

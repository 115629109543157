import { Row, Col, Spin } from 'antd'
import React from 'react'

const Loading = () => {
  return (
    <Row justify={'center'} style={{ marginTop: '20px' }}>
      <Col>
        <Spin size="large" tip="Cargando" />
      </Col>
    </Row>
  )
}

export default Loading
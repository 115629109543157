import React from 'react'
import AppLayout from './AppLayout'

import ConfirmationScreen from './ConfirmationScreen'
import RejectScreen from './RejectScreen'

import { Route, Switch } from 'react-router-dom'

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

const Screens = () => {
  return (
    <Switch>
      <AppLayoutRoute path="/accept/:id" component={ConfirmationScreen} />
      <AppLayoutRoute path="/reject/:id" component={RejectScreen} />
      <Route component={() => <h1>404</h1>} />
    </Switch>
  )
}

export default Screens

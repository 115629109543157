import React from 'react'

import { Breadcrumb, Col, Layout, Row } from 'antd'
import { withRouter } from 'react-router-dom'

const AppLayout = ({ children }) => {

  return (
    <Layout
      style={{
        height: '100vh',
      }}>
      <Row style={{ height: '100vh' }} justify="space-around" align="middle">
        <Col md={24}>
          <Row style={{}} justify="space-around" align="middle">
            <Col md={24} style={{ height: '100vh', backgroundColor: 'white' }}>
              <Breadcrumb />
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default withRouter(AppLayout)

import ApolloClient from 'apollo-boost'

const Client = new ApolloClient({
  uri: process.env.REACT_APP_URL_ENDPOINT,
  request: async operation => {
    operation.setContext({})
  },
})


export { Client }

import { gql } from 'apollo-boost'

const CONFIRM_RESERVE = gql`
  mutation confirmReserve($id: ID!) {
    confirmReserve(_id: $id)
  }
`

const RESERVE_BY_ID = gql`
  query reserveById($id: ID!) {
    reserveById(_id: $id) {
      _id
      blocks {
        weekDay
        period
        box {
          _id
          name
          department {
            _id
            name
          }
        }
      }
      period {
        _id
        configuration {
          from
          name
          to
        }
        from
        to
      }
      professional {
        _id
        firstName
        lastName
      }
      from
      to
    }
  }
`

export { CONFIRM_RESERVE, RESERVE_BY_ID }

import React, { useEffect, useState } from 'react'
import { capitalize, translateDayToSpanish } from '../../../Helpers/dateUtils'
import { CONFIRM_RESERVE } from '../../../Queries'
import { RESERVE_BY_ID } from '../../../Queries'
import style from '../ConfirmationScreen.module.css'

import { Alert, Col, Layout, Row, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loading from '../../../Components/Loading'

const { Content } = Layout
const { Title } = Typography

const Confirmed = () => {
  const location = useLocation()
  const id = location.pathname.replace('/accept/', '')
  const [confirmReserve, { data, error }] = useMutation(CONFIRM_RESERVE, {
    variables: { id },
    errorPolicy: 'all',
  })
  const { loading, data: reserveData } = useQuery(RESERVE_BY_ID, {
    variables: { id },
  })
  const [reserve, setReserve] = useState({})

  const handleError = e => e.toString()

  useEffect(() => {
    if (loading) return
    if (reserveData) setReserve(reserveData.reserveById)

    confirmReserve()

    // eslint-disable-next-line
  }, [reserveData])

  if (loading) return <Loading />
  if (error)
    return (
      <Content>
        <Row justify={'center'} style={{ marginTop: '50px' }}>
          <Col className="text-center">
            <Alert message={handleError(error)} type="error" />
          </Col>
        </Row>
      </Content>
    )

  return reserve._id && data ? (
    <div className={style.confirmContainer}>
      <div className={style.titleContainer}>
        <Title className={style.mainTitle}>RESERVA CONFIRMADA</Title>
        <CheckCircleOutlined className={style.icon} />
      </div>
      <span className={style.timeSpan}>
        {moment().format('DD/MM/YYYY')} a las {moment().format('h:mm')} hrs.
      </span>
      <p>
        Estado
        <strong style={{ color: '#2e3f8c' }}>
          Dr. {reserve.professional.firstName.toUpperCase()}{' '}
          {reserve.professional.lastName.toUpperCase()}
        </strong>
      </p>
      <p>
        Se ha <strong style={{ color: '#6c7bf7' }}>CONFIRMADO</strong> la
        reserva para:
      </p>
      <p className={style.reservetionText}>
        {reserve.blocks.map((block, index) => (
          <span key={index}>
            {block.box.name}{' '}
            {translateDayToSpanish(capitalize(block.weekDay, true))}{' '}
            {block.period} <br />
          </span>
        ))}
      </p>
      <p className={style.reservetionText}>
        Duración:
        {moment(reserve.from)
          .utc()
          .format('DD/MM/YYYY')}{' '}
        -{' '}
        {reserve.to
          ? moment(reserve.to)
              .utc()
              .format('DD/MM/YYYY')
          : 'Indefinido'}{' '}
      </p>
    </div>
  ) : null
}

export default Confirmed

import React, { useEffect, useState } from 'react'
import { capitalize, translateDayToSpanish } from '../../../Helpers/dateUtils'
import { REJECT_RESERVE } from '../../../Queries'
import { RESERVE_BY_ID } from '../../../Queries'
import style from '../RejectScreen.module.css'

import { Alert, Button, Col, Layout, Row, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loading from '../../../Components/Loading'

const { Content } = Layout
const { Title } = Typography

const Rejected = () => {
  const history = useHistory()
  const location = useLocation()
  const id = location.pathname.replace('/reject/', '')
  const [rejectReserve, { data, error }] = useMutation(REJECT_RESERVE, {
    variables: { id },
  })
  const { loading, data: reserveData } = useQuery(RESERVE_BY_ID, {
    variables: { id },
  })
  const [reserve, setReserve] = useState({})

  useEffect(() => {
    rejectReserve()
    if (loading) return
    if (reserveData) setReserve(reserveData.reserveById)
    // eslint-disable-next-line
  }, [reserveData])

  if (loading) return <Loading />
  if (error)
    return (
      <Content>
        <Row justify={'center'} style={{ marginTop: '50px' }}>
          <Col className="text-center">
            <Alert
              message="Hubo un problema al rechazar la reserva. Favor contactar soporte técnico"
              type="error"
            />
            <Button
              block
              onClick={() => history.push('/home')}
              shape="round"
              style={{ marginTop: '10px' }}
              type="ghost">
              Volver a Home
            </Button>
          </Col>
        </Row>
      </Content>
    )

  return reserve._id && data ? (
    <div className={style.rejectContainer}>
      <div className={style.titleContainer}>
        <Title className={style.mainTitle}>RESERVA RECHAZADA</Title>
        <CloseCircleOutlined className={style.icon} />
      </div>
      <span className={style.timeSpan}>
        {moment().format('DD/MM/YYYY')} a las {moment().format('h:mm')} hrs.
      </span>
      <p>
        Estado{' '}
        <strong style={{ color: '#2e3f8c' }}>
          Dr. {reserve.professional.firstName.toUpperCase()}{' '}
          {reserve.professional.lastName.toUpperCase()}
        </strong>
      </p>
      <p>
        Se ha <strong style={{ color: '#ed3a4a' }}>RECHAZADO</strong> la reserva
        para:
      </p>
      <p className={style.reservetionText}>
        {reserve.blocks.map((block, index) => (
          <span key={index}>
            {block.box.name}{' '}
            {translateDayToSpanish(capitalize(block.weekDay, true))}{' '}
            {block.period} <br />
          </span>
        ))}
      </p>
      <p className={style.reservetionText}>
        Duración: {moment(reserve.from).utc().format('DD/MM/YYYY')} -{' '}
        {reserve.to ? moment(reserve.to).utc().format('DD/MM/YYYY') : 'Indefinido'}
      </p>
    </div>
  ) : null
}

export default Rejected

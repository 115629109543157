import React, { useEffect, useState } from 'react'
import Confirmed from './Components'
import './ConfirmationScreen.module.css'

const ConfirmationScreen = props => {
  const [status, setStatus] = useState('CONFIRMED')

  useEffect(() => {
    if (props === 'CONFIRMED') return setStatus('CONFIRMED')
  }, [status, props])

  return <>{status === 'CONFIRMED' ? <Confirmed /> : <></>}</>
}

export default ConfirmationScreen

import React from 'react'
import { ApolloApp } from './Providers'
import { Client } from './ApolloClient'
import Root from './Root'
import Screens from './Screens'

import { BrowserRouter as Router, Route } from 'react-router-dom'

export default function App() {
  return (
    <ApolloApp client={Client}>

        <Router>
          <Route component={Root}>{Screens}</Route>
        </Router>

    </ApolloApp>
  )
}

import moment from 'moment'
import 'moment/locale/es'

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase(),
  )

export const translateDayToSpanish = day => {
  let translate = ''

  switch (day) {
    case 'Monday':
      translate = 'Lunes'
      break
    case 'Tuesday':
      translate = 'Martes'
      break
    case 'Wednesday':
      translate = 'Miércoles'
      break
    case 'Thursday':
      translate = 'Jueves'
      break
    case 'Friday':
      translate = 'Viernes'
      break
    case 'Saturday':
      translate = 'Sábado'
      break
    case 'Sunday':
      translate = 'Domingo'
      break
    default:
      translate = ''
  }

  return translate
}

export const getDaysFromMonth = month => {
  const startOfMonth = moment()
    .month(month)
    .startOf('month')
  const endOfMonth = moment()
    .month(month)
    .endOf('month')
  const days = []
  let day = startOfMonth

  while (day <= endOfMonth) {
    days.push({
      name: day.format('dddd'),
      month: day.locale('es').format('MMMM'),
      number: day.format('DD'),
      date: day.format('DD-MM-YYYY'),
      dateQueryFormat: day.format('YYYY-MM-DD'),
      today: moment().format('DD') === day.format('DD'),
      moment: day,
    })

    day = day.clone().add(1, 'd')
  }
  return days
}

export const formatDateData = date => {
  return {
    name: date.format('dddd'),
    month: date.locale('es').format('MMMM'),
    number: date.format('DD'),
    date: date.format('DD-MM-YYYY'),
    dateQueryFormat: date.format('YYYY-MM-DD'),
    today: moment().format('DD/MM/YYYY') === date.format('DD/MM/YYYY'),
    moment: date,
  }
}
